/**
 * @module FloatingPanel
 * @description
 */
import Image from 'next/legacy/image';
import Button from '@/blocks/Buttons/DynamicButton';
import { fastly } from '@/js/utils/helpers';
import styles from './index.module.css';

const defaultValueProps = [
  <>Upload speeds up to 25x faster than cable<span data-legal="125x-faster-than-cable"/></>,
  'Power hundreds of devices at our highest speeds',
  <>Amazon eero Max 7 router included<span data-legal="eero" /></>
];

const defaultImage = {
  url: '/sections/floating-panel/woman-with-ipad-trimmed.png',
  blurUrl: '/sections/floating-panel/woman-with-ipad-trimmed.png',
  alt: 'Person holding ipad and striking a pose.',
  width: 438,
  height: 575
};

const position = '5G FLOATING PANEL';

/**
 * @function FloatingPanel
 * @description
 */
const FloatingPanel = ( {
  title = 'Experience 5 Gig',
  subtitle = 'Ready for the ultimate upgrade? Join the first and only major internet provider with network-wide Fiber 5 Gig service.',
  valueProps = defaultValueProps,
  image = defaultImage,
  background = '/sections/floating-panel/horizontal-lines.png'
} ) => {
  const backgroundPath = `${ process.env.imagePath }${ background }`;

  return (
    <section className={ `component ${ styles.component }` } style={ { backgroundImage: `url( ${ backgroundPath } )` } }>
      <div className="l-container">
        <div className={ `g-row ${ styles.row }` }>

            <div className={ styles.panel }>
              <h2 className={ styles.title }>{ title }</h2>
              <p className={ styles.subtitle }>{ subtitle }</p>

              { valueProps.length &&
                <ul className={ styles.ul }>
                  { valueProps.map( ( vp, index ) => (
                    <li key={ index } className={ styles.li }>{ vp }</li>
                  ) ) }
                </ul>
              }

              <div className={ styles.buttonContainer }>
                <Button type="cart" position={ position } />
              </div>
            </div>{ /* <!-- .panel --> */ }

            <div className={ styles.imageContainer }>
              <Image
                src={ image.url }
                alt={ image.alt }
                loader={ fastly }
                width={ image.width }
                height={ image.height }
                loading="lazy"
              />
            </div>{ /* <!-- .imageContainer --> */ }

        </div>{ /* <!-- .g-row --> */ }
      </div>{ /* <!-- .g-container --> */ }
    </section>
  );
};

export default FloatingPanel;
