
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
/**
 * @module Home
 * @description Home Page
 */
import { lazy, Suspense, useContext } from 'react';
import Head from 'next/head';
import Template from '@/templates/Default';
import ReturnVisitorModal from '@/blocks/Modals/ReturnVisitorModal';
import ResumeQuoteModal from '@/blocks/Modals/ResumeQuoteModal';

// Temporary Preamp Assets Moved to Base Site
import Hero5G from '@/blocks/Heroes/AddressCheck5G/HeroAddressCheck5G';
import SplitSectionYTTV from '@/blocks/SplitSectionYTTV';
const CarouselP72 = lazy( () => import( '@/blocks/Carousel P72 Lite' ) );
import FloatingPanel from '@/blocks/FloatingPanel';
const ColumnContentP72 = lazy( () => import( '@/blocks/ColumnContent/ColumnContentP72' ) );
import { FuelContext } from '@/providers/fuel-provider';

// Cohesion
import MonarchFallback from '@/blocks/MonarchFallback';
import { MonarchPreampPlacement } from '@redventures/cohesion-utils-react';
import { getBaseAssetName } from '@/js/utils/helpers';

/**
 * @function Page
 * @description Page
 */
const Page = () => {
  const { resumeQuote, phoneHeavy } = useContext( FuelContext );

  return (
    <>
      <Head>
        <title>Fiber Internet, TV & Phone Service Provider | Frontier Internet</title>
        <meta
          name="description"
          content="Get high-speed internet with Frontier! Explore fiber optic internet plans & bundle with TV & phone for incredible value. Ultrafast fiber internet. Shop Now!"
        />
        <link rel="canonical" href="https://frontier.com/" />
      </Head>

      <Template
        title="Frontier Fiber Internet Service Provider"
        modal={
          resumeQuote && ! phoneHeavy ? (
            <ResumeQuoteModal />
          ) : (
            <MonarchPreampPlacement placement="5Of7LlccpzT6qpBB91VQa0" componentId={ getBaseAssetName }>
              <ReturnVisitorModal />
            </MonarchPreampPlacement>
          )
        }
        hero={
          <>
            {/* Home | Banner */}
            <MonarchPreampPlacement placement="5Sw7U4k5F4obLWEHDMWOYA" componentId={ getBaseAssetName }/>

            {/* Home | Hero */}
            <MonarchPreampPlacement
              placement="4mCzMVI47TXrDYCRW0Dk6y"
              componentId={ getBaseAssetName }
              fallback={
                <MonarchFallback height={ [ '533px', '323px', '431px' ] } />
              }
            >
              <Hero5G />
            </MonarchPreampPlacement>
          </>
        }
        primary={
          <>
            {/* Home | Section 1 */}
            <MonarchPreampPlacement
              placement="6bDLCm0rNdPBPCbYQfycna"
              componentId={ getBaseAssetName }
              fallback={
                <MonarchFallback height={ [ '309px', '309px', '323px' ] } />
              }
            >
              <SplitSectionYTTV />
            </MonarchPreampPlacement>

            {/* Home | Section 2 */}
            <MonarchPreampPlacement
              placement="4g7IPuHwin8Uo4slIi3Fkq"
              componentId={ getBaseAssetName }
              fallback={
                <MonarchFallback height={ [ '738px', '600px', '620px' ] } />
              }
            >
              <Suspense>
                <CarouselP72 />
              </Suspense>
            </MonarchPreampPlacement>

            {/* Home | Section 3 */}
            <MonarchPreampPlacement
              placement="7o1SamkWTKAXgnMmfebTrI"
              componentId={ getBaseAssetName }
              fallback={
                <MonarchFallback height={ [ '738px', '420px', '420px' ] } />
              }
            >
              <FloatingPanel />
            </MonarchPreampPlacement>

            {/* Home | Section 4 */}
            <MonarchPreampPlacement
              placement="5zv3XAD18siayIs70Kzvi5"
              componentId={ getBaseAssetName }
              fallback={
                <MonarchFallback height={ [ '738px', '596px', '527px' ] } />
              }
            >
              <Suspense>
                <ColumnContentP72 />
              </Suspense>
            </MonarchPreampPlacement>

            {/* Home | Section 5 */}
            <MonarchPreampPlacement
              placement="2JrI7VXRG8ddH15Rwk1CEv"
              componentId={ getBaseAssetName }
              fallback={ <MonarchFallback height={ [ '0px', '0px', '0px' ] } /> }
            />

            {/* Home | Section 6 */}
            <MonarchPreampPlacement placement="5hGCO98G1o8NKRu39A22ZG" componentId={ getBaseAssetName }/>

            {/* Home | Section 7 */}
            <MonarchPreampPlacement placement="6sUqUDYpPzoORwfL6jGnIa" componentId={ getBaseAssetName }/>

            {/* Home | Section 8 */}
            <MonarchPreampPlacement placement="7wI1O9qQ9Vh43DO0lHizcy" componentId={ getBaseAssetName }/>

            {/* Home | Sticky CTA */}
            <MonarchPreampPlacement placement="7kPU2fJ4O1j9USiPa1Brrt" componentId={ getBaseAssetName }/>
          </>
        }
      ></Template>
    </>
  );
};

export default Page;
